//@ts-check
const CosmosClient = require('@azure/cosmos').CosmosClient
const cosmosConfig = require('./cosmosConfig')

const endpoint = cosmosConfig.endpoint
const key = cosmosConfig.key

const databaseId = cosmosConfig.database.id
const casesContainerId = cosmosConfig.casesContainer.id
const bcContainerId = cosmosConfig.bcContainer.id
const invoiceContainerId = cosmosConfig.invoiceContainer.id
const subsidyRatesContainerId = cosmosConfig.subsidyRatesContainer.id
const auditLogsContainerId = cosmosConfig.auditLogsContainer.id
const partitionKey = { kind: 'Hash', paths: ['/partitionKey'] }

const options = {
      endpoint: endpoint,
      key: key,
      userAgentSuffix: 'CosmosDBJavascriptQuickstart'
    };

const client = new CosmosClient(options) 


/**
  * Read the database definition
 */
async function readDatabase() {
    const { resource: databaseDefinition } = await client
      .database(databaseId)
      .read()
  }
  

/**
  * Read the cases container definition
 */
async function readCasesContainer() {
  const { resource: containerDefinition } = await client
    .database(databaseId)
    .container(casesContainerId)
    .read()
} 

/**
  * Read the bargaining councils container definition
 */
async function readBCContainer() {
  const { resource: containerDefinition } = await client
    .database(databaseId)
    .container(bcContainerId)
    .read()
}

/**
  * Reading the subsidy Rates container definition
 */
async function readSubsidyRatesContainer() {
  const { resource: containerDefinition } = await client
    .database(databaseId)
    .container(subsidyRatesContainerId)
    .read()
}


/**
  * Scale the cases container
 */
async function scaleCasesContainer() {
    const { resource: containerDefinition } = await client
      .database(databaseId)
      .container(casesContainerId)
      .read();
    
    try
    {
        const {resources: offers} = await client.offers.readAll().fetchAll();
    
        const newRups = 500;
        for (var offer of offers) {
          if (containerDefinition._rid !== offer.offerResourceId)
          {
              continue;
          }
          offer.content.offerThroughput = newRups;
          const offerToReplace = client.offer(offer.id);
          await offerToReplace.replace(offer);
          console.log(`Updated offer to ${newRups} RU/s\n`);
          break;
        }
    }
    catch(err)
    {
        if (err.code == 400)
        {
            console.log(`Cannot read container throuthput.\n`);
            console.log(err.body.message);
        }
        else 
        {
            throw err;
        }
    }
  }

/**
  * Scale the bargaining councils container
*/
async function scaleBCContainer() {
  const { resource: containerDefinition } = await client
    .database(databaseId)
    .container(bcContainerId)
    .read();
  
  try
  {
      const {resources: offers} = await client.offers.readAll().fetchAll();
  
      const newRups = 500;
      for (var offer of offers) {
        if (containerDefinition._rid !== offer.offerResourceId)
        {
            continue;
        }
        offer.content.offerThroughput = newRups;
        const offerToReplace = client.offer(offer.id);
        await offerToReplace.replace(offer);
        console.log(`Updated offer to ${newRups} RU/s\n`);
        break;
      }
  }
  catch(err)
  {
      if (err.code == 400)
      {
          console.log(`Cannot read container throuthput.\n`);
          console.log(err.body.message);
      }
      else 
      {
          throw err;
      }
  }
}

/**
  * Scale the bargaining councils container
*/
async function scaleSubsidyRatesContainer() {
  const { resource: containerDefinition } = await client
    .database(databaseId)
    .container(subsidyRatesContainerId)
    .read();
  
  try
  {
      const {resources: offers} = await client.offers.readAll().fetchAll();
  
      const newRups = 500;
      for (var offer of offers) {
        if (containerDefinition._rid !== offer.offerResourceId)
        {
            continue;
        }
        offer.content.offerThroughput = newRups;
        const offerToReplace = client.offer(offer.id);
        await offerToReplace.replace(offer);
        console.log(`Updated offer to ${newRups} RU/s\n`);
        break;
      }
  }
  catch(err)
  {
      if (err.code == 400)
      {
          console.log(`Cannot read container throuthput.\n`);
          console.log(err.body.message);
      }
      else 
      {
          throw err;
      }
  }
}

/**
  * Create case item if it does not exist
 */
async function createCaseItem(itemBody) {
    const { item } = await client
      .database(databaseId)
      .container(casesContainerId)
      .items.upsert(itemBody)
    console.log(`Created a new case item with id:\n${itemBody.id}\n`)
}

/**
  * Create bargaining council item if it does not exist
 */
async function createBCItem(itemBody) {
  const { item } = await client
    .database(databaseId)
    .container(bcContainerId)
    .items.upsert(itemBody)
    console.log(`Created a new bc item with id:\n${itemBody.id}\n`)
}

/**
  * Create subsidy rate item if it does not exist
 */
async function createSubsidyRateItem(itemBody) {
  // create a new subsidy rate item
  const { item } = await client
    .database(databaseId)
    .container(subsidyRatesContainerId)
    .items.upsert(itemBody)
    console.log(`Created a new subsidy rate item with id:\n${itemBody.id}\n`)
}
  
/**
   * Query the container for cases using SQL
 */
async function queryAllCases() {
  // query to return all cases in the cases container
  const querySpec = {
    query: 'SELECT c.id, c.caseNum,c.applicant,c.respondent,c.panelist,c.outcome,c.dateSettledRendered,c.dateProcessed,c.subsidyRate from c'
  }

  const { resources: results } = await client
    .database(databaseId)
    .container(casesContainerId)
    .items.query(querySpec)
    .fetchAll()
    sessionStorage.removeItem("cases");
    sessionStorage.setItem("cases", JSON.stringify(results));
    return results;
}

/**
   * Query the container for bargaining Councils using SQL
 */
async function queryAllBargainingCouncils() {
  // query to return all cases in the cases container
  const querySpec = {
    query: 'SELECT c.id,c.name,c.shortName,c.primaryAddress,c.secondaryAddress,c.contactName,c.contactEmail,c.bankName,c.accountName,c.accountType,c.accountNumber,c.branchName,c.branchCode from c'
  }

  const { resources: results } = await client
    .database(databaseId)
    .container(bcContainerId)
    .items.query(querySpec)
    .fetchAll()
    sessionStorage.setItem("bargainingCouncils", JSON.stringify(results));
    return results;
}

/**
   * Query the container for bargaining Councils using SQL
 */
async function querySpecificBargainingCouncil(bargainingCouncil) {
  // query to return all cases in the cases container
  const querySpec = {
    query: 'SELECT c.name,c.shortName,c.bankName,c.accountName,c.accountType,c.accountNumber,c.branchName,c.branchCode from c WHERE c.name = "'.concat(bargainingCouncil).concat('"')
  }

  const { resources: results } = await client
    .database(databaseId)
    .container(bcContainerId)
    .items.query(querySpec)
    .fetchAll()
    console.log("selected bc is : ",results)
    sessionStorage.setItem("bargainingCouncils", JSON.stringify(results));
    return results;
}


/**
   * Query the container for current subsidy rate using SQL
 */
async function querySubsidyRate(){
  console.log(`Querying container:\n${cosmosConfig.subsidyRatesContainer.id}`)

  // query most current subsidy rate in the subsidy rates container
  const querySpec = {
    query: 'SELECT c.subsidyRate, c.dateInitiated from c'
  }

  const { resources: results } = await client
    .database(databaseId)
    .container(subsidyRatesContainerId)
    .items.query(querySpec)
    .fetchAll()
    console.log("subsidy rate result",results)
    sessionStorage.removeItem("subsidyRate");
    sessionStorage.setItem("subsidyRate", JSON.stringify(results));
    return results;
}

/**
 * * Query cases that have been filtered based on bargaining council and date range
 */
async function queryFilteredCases(bargainingCouncil,dateFrom, dateUntil){
  console.log(`Querying container:\n${cosmosConfig.casesContainer.id}`)

    // query to return all cases in the cases container 
    const querySpec = {
      query: 'SELECT c.id,c.caseNum,c.applicant,c.respondent,c.panelist,c.outcome,c.dateSettledRendered,c.dateProcessed,c.subsidyRate from c WHERE c.bargainingCouncil = "'
      .concat(bargainingCouncil).concat('"').concat(' AND (c.dateSettledRendered BETWEEN "').concat(dateFrom).concat('" AND "').concat(dateUntil).concat('")')
    }

    const { resources: results } = await client
    .database(databaseId)
    .container(casesContainerId)
    .items.query(querySpec)
    .fetchAll()
    sessionStorage.removeItem("cases");
    sessionStorage.setItem("cases", JSON.stringify(results));
    return results;
}

/**
 * * Update a cases using SQL
 */
async function updateCase(caseToUpdate) {
  console.log(`Updating item:\n${caseToUpdate.id}\n`)
  // Updating the cases
  const { item } = await client
    .database(databaseId)
    .container(casesContainerId)
    .item(caseToUpdate.id, caseToUpdate.partitionKey)
    .replace(caseToUpdate)
}

/**
 * * Update a bargaining council using SQL
 */
async function updateBargainingCouncil(bargainingCouncil) {
  console.log(`Updating item:\n${bargainingCouncil.id}\n`)
  // Updating the bargaining council
  const { item } = await client
    .database(databaseId)
    .container(bcContainerId)
    .item(bargainingCouncil.id, bargainingCouncil.partitionKey)
    .replace(bargainingCouncil)
}

/**
  * Create invoice item if it does not exist
 */
async function createInvoiceItem(invoiceBody) {
  const { item } = await client
    .database(databaseId)
    .container(invoiceContainerId)
    .items.upsert(invoiceBody)
    console.log(`Created invoice item with id:\n${invoiceBody.id}\n`)
}

/**
   * Query the invoice container for invoices using name of bargaining council
 */


async function searchInvoice(searchInvoiceInput) {
  console.log(`Querying container:\n${cosmosConfig.invoiceContainer.id}`)

  // query to return all cases in the cases container
  const querySpec = {
    //query: 'SELECT * FROM c WHERE c.bcName LIKE "Bentley%"'
    query: 'SELECT * FROM c WHERE c.bcName LIKE '.concat(searchInvoiceInput).concat(' OR c.invoiceNo LIKE').concat(searchInvoiceInput)
  }

  const { resources: results } = await client
    .database(databaseId)
    .container(invoiceContainerId)
    .items.query(querySpec)
    .fetchAll()
    console.log("invoices result",results)
    sessionStorage.setItem("invoices", JSON.stringify(results));
    return results;
}

/**
 * * Update a bargaining council using SQL
 */
async function updateInvoiceCounter(invoiceBody) {
  console.log(`Updating item:\n${invoiceBody.id}\n`)
  // Updating the bargaining council
  invoiceBody.invoiceCounter++;
  console.log(invoiceBody.invoiceCounter)
  const { item } = await client
    .database(databaseId)
    .container(invoiceContainerId)
    .item(invoiceBody, invoiceBody.partitionKey)
    .replace(invoiceBody)
}

async function queryInvoiceCounterItem() {
  console.log(`Querying container:\n${cosmosConfig.invoiceContainer.id}`)

  // query to return all cases in the cases container
  const querySpec = {
    query: 'SELECT * from c WHERE c.id = "invoiceNumberGenerator"'
  }

  const { resources: results } = await client
    .database(databaseId)
    .container(invoiceContainerId)
    .items.query(querySpec)
    .fetchAll()
    console.log(results)
    sessionStorage.setItem("invoices", JSON.stringify(results));
    return results;
}

/**
 *  Query cases to check if case number already exists
 */
async function checkCaseNumber(caseNumber){
  console.log(`Querying container:\n${cosmosConfig.casesContainer.id}`)
  console.log(caseNumber)

  // query to return all cases in the cases container
  const querySpec = {
    query: 'SELECT c.caseNum from c WHERE c.caseNum = "' + caseNumber+'"'
  }

  const { resources: results } = await client
  .database(databaseId)
  .container(casesContainerId)
  .items.query(querySpec)
  .fetchAll()
  let resultString = JSON.stringify(results)
  console.log(`\tCase Number is  ${resultString}\n`)
  console.log(`\tLength of results is ${resultString.length}\n`)

  if (resultString.length == 2) {
    return false;
  }else {
    return true;
  }
}

/**
  * Create audit log for cancel invoice reason
 */
async function createAuditLog(itemBody) {
  const { item } = await client
    .database(databaseId)
    .container(auditLogsContainerId)
    .items.upsert(itemBody)
    console.log(`Created audit log with id:\n${itemBody.id}\n`)
}

/**
   * Query the container for cases using SQL
 */
async function queryAllAuditLogs() {
  console.log(`Querying container:\n${cosmosConfig.auditLogsContainer.id}`)

  // query to return all cases in the cases container
  const querySpec = {
    query: 'SELECT * from c'
  }

  const { resources: results } = await client
    .database(databaseId)
    .container(auditLogsContainerId)
    .items.query(querySpec)
    .fetchAll()
    console.log("audit logs result",results)
    sessionStorage.removeItem("auditTrail");
    sessionStorage.setItem("auditTrail", JSON.stringify(results));
    return results;
}



let searchValue;
function returnSearchInput() {
  console.log(searchValue);
  return searchValue;
}
function setSearchInput(searchValueInput) {
  searchValue = searchValueInput;
}

let filteringCriteria;
function returnFilteringCriteria() {
  console.log(filteringCriteria);
  return filteringCriteria;
}
function setFilteringCriteria(filteringCriteriaInput) {
  filteringCriteria = filteringCriteriaInput;
}

let filteredCases;
function returnFilteredCases() {
  console.log(filteredCases);
  return filteredCases;
}
function setFilteredCases(filteredCasesInput) {
  filteredCases = filteredCasesInput;
}

let bentley;
function returnBentley() {
  console.log(bentley);
  return bentley;
}
function setBentley(bentleyInput) {
  console.log("Now setting Bentley data")
  bentley = bentleyInput;
  console.log(bentley)
}

let auditUserNameInput;
function setAuditUserName(auditUserName) {
  auditUserNameInput = auditUserName;
  console.log(auditUserNameInput)
}

function getAuditUserName(){
  return auditUserNameInput
}

module.exports = {setAuditUserName, getAuditUserName, setBentley, returnBentley, setSearchInput, returnSearchInput,setFilteringCriteria,
  returnFilteringCriteria, setFilteredCases,returnFilteredCases, queryAllCases, queryAllBargainingCouncils, querySpecificBargainingCouncil,
  querySubsidyRate,queryFilteredCases, createBCItem, createCaseItem, createSubsidyRateItem, updateBargainingCouncil, updateCase, createInvoiceItem,
  searchInvoice, updateInvoiceCounter, queryInvoiceCounterItem, checkCaseNumber, queryAllAuditLogs, createAuditLog};
